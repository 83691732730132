import { getItem } from '../../../../api/api-methods';
import { getFullName } from '../../../shared/helpers/dataUtils';
import { fetchGetGroup } from '../../../shared/helpers/groupOptions';
import KnLocalAlert from '../../../shared/components/KnLocalAlert.vue';
import KnBackToTopButton from '../../../shared/components/KnBackToTopButton.vue';
import KnFormActionButtons from '../../../shared/components/KnFormActionButtons/KnFormActionButtons.vue';
import KnFormTitle from '../../../shared/components/KnFormTitle.vue';
import { mapState } from 'vuex/dist/vuex.common.js';

// };
export default {
  name: 'KnMoveGroupAToGroupB',
  components: {
    KnLocalAlert,
    KnBackToTopButton,
    KnFormActionButtons,
    KnFormTitle,
  },
  data() {
    return {
      fullWidth: false,
      mainTitle: 'Acciones de grupo',
      headersA: [
        { text: 'Nombre', value: 'nombre_completo' },
        // { text: 'Grupo anterior', value: 'previousGroups' },
        // { text: 'Acciones', value: 'actions', sortable: false },
      ],
      headersB: [
        { text: 'Nombre', value: 'nombre_completo' },
        // { text: 'Grupo anterior', value: 'previousGroups' },
        // { text: 'Acciones', value: 'actions', sortable: false },
      ],
      groupId: undefined,
      itemsA: [],
      itemsB: [],
      searchA: '',
      searchB: '',
      selectedA: [],
      selectedB: [],
      recentItemsB: [],
      loadingA: false,
      loadingB: false,
      dataSources: [],
      selectedDataSource: undefined,
      group: undefined,
      // Alert component
      showAlert: false,
      alertText: '',
      alertType: '',
      alertColor: null,
      loading: false,
      errors: [],
      loadingPage: false,
      loadingGroups: false,
    };
  },
  computed: {
    ...mapState(['institutionId']),
    filteredItemsA() {
      const search = this.searchA ? this.searchA.toLowerCase() : '';
      if (this.loadingA) return;
      return Array.isArray(this.itemsA)
        ? this.itemsA.filter((item) =>
            item?.nombre_completo?.toLowerCase().includes(search)
          )
        : this.itemsA;
    },
    filteredItemsB() {
      if (this.loadingB) return;
      const search = this.searchB ? this.searchB.toLowerCase() : '';
      return Array.isArray(this.itemsB)
        ? this.itemsB.filter((item) =>
            item?.nombre_completo?.toLowerCase().includes(search)
          )
        : this.itemsB;
    },
    invalidFields() {
      return false;
    },
  },

  async created() {
    this.loadingPage = true;
    this.setLoadingState(true, 'Por favor, espere. Cargando...', 'info');
    try {
      const paramId = this.$route.params.id;
      if (paramId) {
        this.groupId = this.validateId(paramId);
        await this.loadGroup();
      }
      await this.fillDataForm();
    } catch (error) {
      console.error('Error in created:', error);
      this.error = 'Error al cargar datos. ';
      this.setErrorState(
        'Error al cargar datos. Por favor, inténtelo de nuevo.'
      );
    } finally {
      this.loadingPage = false;
      this.setLoadingState(false);
    }
  },
  methods: {
    //#region alert methods
    setLoadingState(loading, alertText = '', alertType = 'info') {
      this.loading = loading;
      this.alertText = alertText;
      this.showAlert = loading;
      this.alertType = alertType;
    },

    setErrorState(alertText) {
      this.errors.push(alertText);
      this.loading = false;
      this.alertText = alertText;
      this.alertType = 'error';
      this.showAlert = true;
    },

    setSuccessState(alertText) {
      this.loading = false;
      this.alertText = alertText || this.successAlertText;
      this.alertType = 'success';
      this.alertColor = 'success';
      this.showAlert = true;
    },

    setWarningState(warningText) {
      this.warnings.push(warningText);
      this.alertText = warningText;
      this.alertType = 'warning';
      this.alertColor = 'warning';
      this.showAlert = true;
    },
    //#endregion

    validateId(orderId) {
      if (!/^\d+$/.test(orderId)) {
        this.error = 'Formato de ID no válido.';
        throw new Error('Formato de ID no válido');
      }
      return orderId;
    },

    getGroupImage(group) {
      if (!group || !group.imagen_grupo || !group.imagen_grupo.imagen) {
        return require('../../../../assets/images/huellita.png'); // Default image
      } else {
        return group.imagen_grupo.imagen;
      }
    },

    getSchoolYear(group) {
      if (!group || !group.ciclo_escolar || !group.ciclo_escolar.dato) {
        return 'School year unavailable';
      } else {
        return group.ciclo_escolar.dato;
      }
    },

    async loadGroup() {
      try {
        this.loadingA = true;
        const { data } = await fetchGetGroup(this.groupId);
        console.log('loadGroup A data', data);

        this.group = data;
        this.itemsA = data.alumnos;
        await this.populateStudentInfo(this.itemsA);
      } catch (error) {
        console.error('Error loading subject data:', error);
      } finally {
        this.loadingA = false;
      }
    },
    async fillDataForm() {
      const groupsRes = await getItem(
        `app-personas/filters/grupo?institucion_educativa=${this.institutionId}&estatus_sistema=true`
      );
      this.dataSources = groupsRes.results;
    },
    async populateStudentInfo(array) {
      for (const item of array) {
        // const personalData = await getItem(
        //   `app-personas/datos-personales/${item.}`
        // );
        const personalData = item.datos_personales;
        item.nombre_completo = getFullName({ ...personalData });
      }
    },
    async getGroups(name) {
      this.loadingGroups = true;
      try {
        const searchFilter = name !== null ? `&nombre_grupo=${name}` : '';
        const responseData = await getItem(
          `/app-personas/filters/grupo?institucion_educativa=${this.institutionId}&estatus_sistema=true&limit=10${searchFilter}`
        );
        this.dataSources = responseData.results;
      } catch (error) {
        console.error('Error al obtener materias:', error);
      } finally {
        this.loadingGroups = false;
      }
    },

    getPreviousGroup() {
      const previousGroup = this.dataSources.find(
        (e) => e.id === this.selectedDataSource
      );
      return previousGroup ? previousGroup.nombre_grupo : '';
    },
    moveItem(item, from, to) {
      if (!to.some((i) => i.id === item.id)) {
        item.previousGroup = this.getPreviousGroup();
        to.push(item);
        this[from] = this[from].filter((i) => i.id !== item.id);
      }
    },

    moveAll(from, to) {
      this[from].forEach((item) => {
        this.moveItem(item, from, to);
      });
      this[from] = [];
    },
    moveSelected(selected, from, to) {
      selected.forEach((item) => {
        this.moveItem(item, from, to);
      });
      this[`selected${from.charAt(from.length - 1).toUpperCase()}`] = [];
    },
    moveItemToB(item) {
      this.moveItem(item, 'itemsA', this.itemsB);
    },
    moveItemToA(item) {
      this.moveItem(item, 'itemsB', this.itemsA);
    },
    moveAllToB() {
      this.moveAll('itemsA', this.itemsB);
    },
    moveAllToA() {
      this.moveAll('itemsB', this.itemsA);
    },
    moveSelectedToB() {
      this.moveSelected(this.selectedA, 'itemsA', this.itemsB);
    },
    moveSelectedToA() {
      this.moveSelected(this.selectedB, 'itemsB', this.itemsA);
    },
    async updateDataSource(item) {
      this.loadingB = true;
      const group = this.dataSources.find((element) => element.id === item);
      if (group) {
        this.itemsB = group.alumnos;
        await this.populateStudentInfo(this.itemsB);
      }
      this.loadingB = false;
    },

    async handleSearch(item) {
      await this.getGroups(item);
    },
    actionAlertBtn1() {
      console.log('Action');
    },
    continueAdding() {
      console.log('Continued');
    },

    save() {},
    returnToTable() {},
  },
};
